@use "@angular/material" as mat;
@import "colors";
@import "sizes";
@include mat.core();

$my-app-primary: mat.define-palette(mat.$blue-palette, 700);
$my-app-accent: mat.define-palette(mat.$amber-palette, 600);
$my-app-warn: mat.define-palette(mat.$red-palette, 500);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include mat.all-component-themes($my-app-theme);

// ------------------- Dialogs ------------------
.mat-dialog-container {
  padding: 1.75rem !important;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.mat-dialog-title {
  margin: 0 0 1.75rem !important;
  display: block;
}

.mat-dialog-container {
  overflow: hidden;
  border-radius: 10px !important;
}
.mat-sort-header-content {
  user-select: none;
}

.fileRepo-modalbox {
  mat-dialog-container {
    background-color: $mat-card-color !important;
  }
}
.question-details-modal {
  mat-dialog-container {
    background-color: $mat-card-color !important;
    padding: 0 !important;
  }
}
// ------------------- icons ------------------
.mat-button-focus-overlay {
  background-color: transparent !important;
}
.mat-menu-panel {
  max-width: unset !important;
  min-width: unset !important;
  min-height: unset !important;
}
.mat-menu-content {
  padding: 0 !important;
  overflow: hidden !important;
  width: fit-content !important;
  display: flex !important;
  flex-direction: column !important;
}
.mat-menu-item {
  padding: 0.5rem !important;
  font-size: 1.3rem;
  display: flex !important;
  align-items: center;
}
.mat-button {
  min-width: none !important;
}
.mat-icon {
  height: auto !important;
  width: auto !important;
  line-height: 2.1rem !important;
}
.mat-icon-button {
  height: 3rem !important;
  width: 3rem !important;
  line-height: 2.1rem !important;
}
.material-icons,
.material-icons-outlined {
  font-size: 2.1rem !important;
}
.mat-button-wrapper {
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.mat-mini-fab .mat-button-wrapper {
  padding: 0 !important;
  line-height: 0 !important;
}
.mat-mini-fab {
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  line-height: 0 !important;
}
.mat-paginator {
  background-color: inherit;
  font-size: 1rem !important;
}
.mat-paginator-icon {
  width: 2.1rem !important;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.mat-paginator-range-label {
  margin: 0 !important;
}
.mat-paginator-container {
  min-height: unset !important;
}

.mat-badge-content {
  background: var(--login-border-icon-color) !important;
}

// ----------------- mat-form-field -------------
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0 0.8em 0 !important;
  border-top: 0.3em solid transparent !important;
}
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f5f7f9 inset;
  -webkit-box-shadow: 0 0 0px 1000px #f5f7f9 inset;
}
.mat-form-field {
  font-size: 1rem;
  color: $black !important;
  display: inline-block !important;
  height: fit-content !important;
  margin-top: 0 !important;
  border: 0 !important;

  .mat-input-element {
    line-height: 1em !important;
  }

  .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
  .mat-form-field-flex {
    align-items: center !important;
  }
  .mat-form-field-disabled .mat-form-field-underline {
    background-color: transparent !important;
  }
  .mat-form-field-label {
    color: $black !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: var(--dialog-color) !important;
  }

  .mat-form-field-flex:hover .mat-form-field-outline-thick {
    color: var(--dialog-color) !important;
  }
  .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: var(--dialog-color) !important;
  }
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--dialog-color) !important;
  }
  .mat-form-field-wrapper {
    margin: 0 !important;
  }
  .mat-form-field-underline {
    background-color: var(--dialog-color) !important;
  }
  .mat-form-field.ng-dirty.ng-invalid .mat-form-field-underline {
    background-color: #f44336 !important;
  }
  .mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
    color: #f44336 !important;
  }
  .mat-form-field-ripple {
    background-color: var(--dialog-color) !important;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
    background-color: #f44336 !important;
  }
  .mat-form-field-placeholder {
    color: var(--dialog-color) !important;
  }
}

// ----------------- mat-dropdown -------------
.mat-select-panel:not([class*="mat-elevation-z"]) {
  // input width + 2 paddings(0.75) - 2 border width
  min-width: calc(100% + 1.5rem) !important;
}

.mat-select-panel-wrap {
  // translateX 1 paddings(0.75)  + 1 border
  // translateY 1rem
  transform: translateX(-0.75rem) translateY(1.25rem) !important;
}

.cdk-overlay-pane {
  transform: translateX(0px) translateY(0px) !important;
}
mat-option {
  color: $black !important;
}

// ----------------- mat-select -------------
.mat-select {
  .mat-select:focus .mat-select-trigger .mat-select-arrow {
    color: var(--dialog-color) !important;
  }
  .mat-select-arrow {
    color: var(--dialog-color);
    font-weight: 500;
  }
  mat-label {
    color: $black !important;
  }
  mat-option {
    color: $black !important;
  }
  mat-option[aria-selected="true"] {
    color: $black !important;
  }
  mat-option.mat-selected:not {
    color: $black !important;
  }
}
.mat-checkbox-frame {
  border-color: grey !important;
  background-color: white !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 5px;
}
.mat-checkbox-layout {
  width: 100% !important;
  align-items: center !important;
  line-height: 1.5rem !important;
}
.mat-checkbox-label {
  display: flex !important;
  width: calc(100% - 1.5rem - 8px) !important;
  line-height: 1.5rem !important;
}
.mat-checkbox-inner-container {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 5px;
}
.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 1.5rem) !important;
  top: calc(50% - 1.5rem) !important;
  height: 3rem !important;
  width: 3rem !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--dialog-color) !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--dialog-color) !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: var(--dialog-color) !important;
}
.mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--dialog-color) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--dialog-color) !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--dialog-color) !important;
}

.mat-hint {
  color: var(--dialog-color) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mat-input {
  color: var(--dialog-color) !important;
}
.mat-list-item {
  padding: 0.5vw 0 !important;
  height: fit-content !important;
  font-size: 1.3rem !important;
}

.mat-list-text,
.mat-list-item-content.mat-list-item-content-reverse {
  padding: 0 !important;
}

.mat-list-item-content.mat-list-item-content-reverse:hover,
mat-list-option.mat-list-item.mat-list-option.mat-focus-indicator.ng-tns-c293-3.mat-accent.ng-star-inserted.mat-list-single-selected-option {
  background-color: lightgray;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--card-header-color);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--dialog-color);
}

// ----------------- mat-spinner -----------------
.mat-progress-spinner {
  circle {
    stroke: var(--login-border-icon-color);
    stroke-linecap: round;
  }
}

// ----------------- mat-buttons -----------------
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 !important;
}
